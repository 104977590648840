import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import SEO from "./SEO";

class LayoutEditorial extends Component {
  render() {
    return (
      <div className="bg-gray-800">
        <SEO
          title={this.props.title}
          description={this.props.description}
          image={this.props.image}
        />

        <div className="bg-gray-800">
          <Header />
          <article className="w-5/6 mt-12 lg:max-w-3xl m-auto prose-xl self-center">
            <div>
              <main>{this.props.children}</main>
            </div>
          </article>
        </div>
      </div>
    );
  }
}

LayoutEditorial.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutEditorial;
