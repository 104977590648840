import React from "react";
import Img from "gatsby-image";
import Twitter from "../../icons/twitter.svg";
import Github from "../../icons/github.svg";
import LinkedIn from "../../icons/linkedin.svg";

const HelloCard = (props) => {
  return (
    <div className="flex flex-wrap flex-col pt-10 pb-10 lg:pl-16 w-5/6 lg:max-w-4xl lg:flex-row rounded-xl mb-10 m-auto text-gray-300 bg-gray-900 shadow-xl">
      <div className="flex-1">
        <Img
          fluid={props.profileImage}
          alt="Bilal Elreda profile picture."
          className="w-56 lg:w-64 m-auto rounded-xl lg:inset-0"
        />
      </div>
      <div className="flex-1 text-center prose-xl m-auto mt-10 lg:ml-24 lg:mt-16 lg:object-center lg:text-left">
        <h2>Get in touch</h2>
        <span className="font-semibold">
          <a href="mailto:hello@bilalelreda.com" className="underline font-semibold">hello@bilalelreda.com</a>
        </span>
        <div className="flex flex-row mt-12 justify-center lg:justify-start">
          <a
            href="https://twitter.com/bilalelreda"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter height="40" width="40" fill="#FFF" />
          </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://github.com/bilalelreda"
            target="_blank"
            rel="noreferrer"
          >
            <Github height="40" width="40" fill="#FFF" />
          </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://www.linkedin.com/in/bilalelreda/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn height="40" width="40" fill="#FFF" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HelloCard;
