import * as React from "react";
import LayoutEditorial from "../components/LayoutEditorial";
import { useStaticQuery, graphql } from "gatsby";
import HelloCard from "../components/HelloCard";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "profile-green.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div>
      <LayoutEditorial title="Hello">
        <h1 className="text-white">Hello.</h1>
        <h2 className="text-white">Nice to meet you. I'm Bilal.</h2>
        <h3 className="text-white">
          My passion is to help build the future I wish to see.
        </h3>
        <p className="text-gray-300">
          I use technology to bring order and predictability to my life, which
          in turn allows me to have greater productivity and peace of mind. I
          have been fortunate to be able to help people and brands do
          the same.
        </p>
        <p className="text-gray-300">
          I learned basic coding skills at a young age. I built simple programs
          using BASIC to help solve my math homework <em>fast</em>, and I
          learned how to present information on the web using HTML. These two
          skills would become the bedrock to my quest of self-discovery and
          growth as a professional.
        </p>
        <p className="text-gray-300">
          I have since continuously evolved my skillset, by taking a first
          principles approach and breaking down complex systems into discrete
          parts. This has enabled me to stitch together systems and experiences that get 
          out of the way and are delightful to use.
        </p>
        <p className="text-gray-300">
          I currently practice my craft at{" "}
          <a href="https://deutschla.com" target="blank" className="underline">
            Deutsch LA
          </a>{" "}
          as SVP, Technology Director, where I have produced world-class solutions
          for brands like Volkswagen, Letgo, Siemens, Almond Board of
          California, Foster Farms, Keurig Dr Pepper, and our own.
        </p>
        <h4 className="text-white">
          I see challenges as opportunities and I help people and brands in the
          quest of conquering the next horizon.
        </h4>
        <br />
      </LayoutEditorial>
      <HelloCard profileImage={data.profile.childImageSharp.fluid} />
    </div>
  );
};

export default IndexPage;
